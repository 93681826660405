<script>
import Layout from "@/router/layouts/franchise";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";

export default {
  locales: {
    pt: {
      months: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],

      "Withdrawal History": "Histórico de Saques",
      Bank: "Banco",
      Value: "Valor",
      Fee: "Taxa",
      Transfered: "Transferido",
      Transaction: "Transação",
      "Awaiting processing": "Aguardando processamento",
      Completed: "Completo",
      "View Transaction": "Ver Transação",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      months: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],

      "Withdrawal History": "Historia de Retirada",
      Bank: "Banco",
      Value: "Valor",
      Fee: "Taxa",
      Transfered: "Transferido",
      Transaction: "Transacción",
      "Awaiting processing": "En espera de procesamiento",
      Completed: "Completo",
      "View Transaction": "Ver Transacción",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: {
    Layout,
    VclList,
  },
  data() {
    return {
      table: {
        heade: ["Date", "Bank", "Value", "Status"],
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },
    };
  },
  methods: {
    getWithdrawals() {
      this.table.body = null;

      this.table.loading = true;
      this.table.errored = false;
      this.table.empty = false;

      api
        .get("franchise/wallet/withdrawal")
        .then((response) => {
          if (response.data.status == "success") {
            this.table.body = response.data.list;
          } else {
            this.table.body = null;
          }
        })
        .catch((error) => {
          this.table.errored = error;
        })
        .finally(() => {
          this.table.loading = false;
          if (this.table.body == "" || this.table.body == null) {
            this.table.empty = true;
          }
        });
    },
  },
  mounted() {
    this.getWithdrawals();
  },
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-flex justify-content-between">
      <h4 class="mb-0 font-size-18">Carteira › {{ t("Withdrawal History") }}</h4>
    </div>

    <div class="card">
      <div class="card-body">
        <div v-if="table.errored">
          {{
            t(
              "We apologize, we are unable to retrieve information at this time. Please try again later."
            )
          }}
        </div>
        <div v-else-if="table.empty">{{ t("No records found.") }}</div>
        <div v-else class="table-responsive">
          <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
          <table v-else class="table table-centered table-nowrap">
            <thead class="thead-light">
              <tr>
                <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(td, index) in table.body" :key="index">
                <td v-for="(data, row) in td" :key="row">
                  <div v-if="row === 'value'">
                    {{ data | currency }}
                  </div>
                  <div v-else-if="row === 'status'">
                    <span
                      v-if="data === '0'"
                      class="badge badge-soft-warning font-size-12"
                      >{{ t("Awaiting processing") }}</span
                    >
                    <span
                      v-else-if="data === '1'"
                      class="badge badge-soft-success font-size-12"
                      >{{ t("Completed") }}</span
                    >
                    <span
                      v-else-if="data === '2'"
                      class="badge badge-soft-danger font-size-12"
                      >{{ t("Canceled") }}</span
                    >
                    <span
                      v-else-if="data === '3'"
                      class="badge badge-soft-success font-size-12"
                      >{{ t("Completed") }}</span
                    >
                  </div>
                  <div v-else-if="row === 'payment'">
                    {{ data }}
                  </div>
                  <div v-else-if="row === 'bank'">
                    {{ data.banco.numero }} - {{ data.banco.nome }}<br />
                    <strong>Agência:</strong> {{ data.agencia.numero }}-{{
                      data.agencia.digito
                    }}
                    <strong>Conta:</strong> {{ data.conta.numero }}-{{
                      data.conta.digito
                    }}
                  </div>
                  <div v-else class="notranslate">
                    {{ data }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
</template>
